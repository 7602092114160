import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="breadcrumb-area breadcrumb-sub-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb nav-breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section className="sport-safety-area">
      <div className="tab-content-area">
        <div className="container simple-page">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="home-link">
                    <h1>NOT FOUND</h1>
                    <p>
                      You just hit a route that doesn&#39;t exist... the
                      sadness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
